<template>
  <section id="attachment-barcode-component">
    <div
      class="case-creation__step case-creation__test-information"
      v-loading="loading"
    >
      <el-row :gutter="20">
        <el-col :xl="24" :lg="24">
          <div class="case-creation__card attachments">
            <div
              class="case-creation__heading test-attacments border-bottom-none"
            >
              <div class="title-with-icon width-200">
                <span class="icon">
                  <img
                    src="@/assets/images/icons/icon-attachments.svg"
                    alt="icon"
                    class="img-fluid"
                  />
                </span>
                <h3 class="title">E-requisition</h3>
              </div>
            </div>
            <ul class="requisition-action-buttons">
              <li>
                <el-dropdown>
                  <el-button>
                    <img
                      src="@/assets/images/printer-icon-white.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                    Print
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu class="print-options-btn">
                      <el-dropdown-item command="true">
                        <router-link
                          :to="{
                            name: 'ErequisitionCasePrintData',
                            params: {
                              case_id: this.$route.params.case_id,
                            },
                            query: { isColorPrint: true },
                          }"
                          class="print-option-link"
                          target="_blank"
                        >
                          Color Report
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item command="false">
                        <router-link
                          :to="{
                            name: 'ErequisitionCasePrintData',
                            params: {
                              case_id: this.$route.params.case_id,
                            },
                            query: { isColorPrint: false },
                          }"
                          class="print-option-link"
                          target="_blank"
                        >
                          Black & White Report
                        </router-link>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
              <li>
                <el-button @click="onViewReq">
                  <img
                    src="@/assets/images/icons/actions/view-icon-white.svg"
                    alt=""
                    class="img-fluid icon"
                  />
                  VIEW
                </el-button>
              </li>
              <li>
                <el-dropdown
                  placement="bottom"
                  @command="(command) => downloadReport(command)"
                >
                  <el-button>
                    <img
                      src="@/assets/images/icons/actions/download-new-white.svg"
                      alt=""
                      class="img-fluid icon"
                    />
                    DOWNLOAD
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu class="print-options-btn">
                      <el-dropdown-item command="true" class="download-option">
                        Color Report
                      </el-dropdown-item>
                      <el-dropdown-item command="false" class="download-option">
                        Black & White Report
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper],
  computed: {
    ...mapGetters("cases", [
      "getReport",
      "getDownloadReportStatus",
      "getCaseInformation",
    ]),
    ...mapGetters("errors", ["getError"]),
  },
  mounted() {},
  data() {
    return {
      reportUrl: "",
      loading: false,
    };
  },
  methods: {
    onViewReq() {
      let routerData = this.$router.resolve({
        name: "ErequisitionView",
        params: {
          case_id: this.$route.params.case_id,
        },
      });
      window.open(routerData.href, "_blank");
    },
    async downloadReport(status) {
      try {
        this.loading = true;
        let params = {
          download: true,
          caseId: this.$route.params.case_id,
          is_color_variant: status,
        };
        await this.$store.dispatch("cases/downloadRequisitionForm", params);
        if (this.getDownloadReportStatus) {
          sendNotification("Report Downloaded Successfully");
        } else {
          errorNotification("Error in downloading report");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    printPreview() {
      let id = this.$route.params.case_id;

      let routerData = this.$router.resolve({
        name: "ErequisitionCasePrintData",
        params: {
          case_id: id,
        },
        query: this.$route.query,
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>
<style lang="scss">
#attachment-barcode-component {
  .requisition-action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
    margin-top: 1px;
    margin-right: 0px;

    li {
      &:not(:last-child) {
        margin-right: 15px;
      }

      .el-button {
        padding: 0 15px;
        background-color: #273480;
        color: #ffffff;
        min-height: 32px;
        line-height: 1;
        border: none;
        border-radius: 6px;
        text-align: center;
        margin: 2px;
        span {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      }
      &:nth-child(1) {
        .el-button {
          background: #4bb543;
        }
      }
    }
  }
  .el-dropdown-menu__item {
    padding: 0 10px;
    font-size: 12px;
  }
  iframe {
    ::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #ebebeb;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #6d6d6d;
      height: 10px;
    }
  }
}
.el-dropdown-menu {
  padding: 0;
  .el-dropdown-menu__item {
    padding: 0;
    a {
      color: #000;
      background: transparent;
    }
  }
  .download-option {
    color: #000;
    background: transparent;
    padding: 0 20px !important;
    &:hover {
      color: #000;
    }
  }
}
</style>
